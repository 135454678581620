<template>
  <div>
    <div v-if="date" :id="dynamicId" :class="`${ellipseClassName} wrapper`">
      {{ formatDate }}
    </div>
    <b-tooltip :disabled.sync="isTooltipDisabled"
               :target="dynamicId"
               triggers="hover">
      {{ formatDate }}
    </b-tooltip>
  </div>
</template>
<script>
export default {
    name: 'DateValue',
    props: {
        date: {
            type : String,
            default : () => {return '';}
        },
        formatDate: {
            type : String,
            default : () => {return '';}
        },
        ellipseClassName: {
            type : String,
            default : () => {return '';}
        },
        dynamicId: {
            type: String,
            default: () => {
                return '';
            }
        },
        isTooltipDisabled: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    }
};
</script>

<template>
  <div :class="className">
    <TextValue :class-name="className"
               :ellipse-class-name="ellipseClassName"
               :get-text="getText()"
               :text="text"
               :is-paragraph="isParagraph"
               :is-reason-note="isReasonNote"
               :dynamic-id="dynamicIdText"
               :is-tooltip-disabled="isTextTooltipDisabled"
    />
    <NoteValue :get-text-for-reason-note="getTextForReasonNote()"
               :is-reason-note="isReasonNote"
               :ellipse-class-name="ellipseClassName"
               :dynamic-id="dynamicIdNote"
               :is-tooltip-disabled="isTextTooltipDisabled"
    />
    <DateValue :date="date"
               :format-date="formatDate(date)"
               :ellipse-class-name="ellipseClassName"
               :dynamic-id="dynamicIdDate"
               :is-tooltip-disabled="isTextTooltipDisabled"
    />
  </div>
</template>

<script>
import Resizer from '@/utils/Resizer';
import '@/utils/StringExtension';
import TextValue from "@/components/TextFormat/TextValue.vue";
import NoteValue from "@/components/TextFormat/NoteValue.vue";
import DateValue from "@/components/TextFormat/DateValue.vue";
import generateRandomNumber from "@/utils/RandomGenerator";
export default {
    name: 'TextContainer',
    components: {DateValue, NoteValue, TextValue},
    mixins: [Resizer],
    data(){
        return {
            maxSize: 25,
            maxSizeForNote: 15,
            isTextTooltipDisabled: true
        };
    },
    mounted() {
        window.addEventListener(this.resizeEvent, this.onResizeTextContainer);
        this.isTextTooltipDisabled = window.innerWidth >= this.hugeWindowWidth;
    },
    beforeDestroy() {
        window.removeEventListener(this.resizeEvent, this.onResizeTextContainer);
    },
    computed : {
        dynamicIdText() {
            return `tooltip-target-${this.makeid(100)}`;
        },
        dynamicIdNote() {
            return `tooltip-note-target-${this.makeid(100)}`;
        },
        dynamicIdDate() {
            return `tooltip-date-target-${this.makeid(100)}`;
        },
    },
    props: {
        className: {
            type: String,
            default: () => {
                return '';
            },
        },
        text: {
            type: String,
            default: () => {
                return '';
            },
        },
        date: {
            type: String,
            default: () => {
                return '';
            },
        },
        isParagraph: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        isReasonNote: {
            type: Boolean,
            default: () => {
                return false;
            },
        }
    },
    methods: {
        formatDate(date) {
            if (!date) return 'N/A';
            const dateWithoutT = date.replace('T', ' ');
            const sanitizedDate = dateWithoutT.slice(0, dateWithoutT.lastIndexOf(':'));
            switch (true) {
            case (!sanitizedDate):
            case (sanitizedDate.length === 0):
                return 'N/A';
            case (sanitizedDate.length > this.maxSize):
                return sanitizedDate.slice(0, this.maxSize);
            default:
                return sanitizedDate;
            }
        },
        getText(){
            switch (true) {
            case (!this.text):
            case (this.text.length === 0):
                return 'N/A';
            case (this.text.length > this.maxSize):
                return `${this.text.substring(0, this.maxSize)}...`.trim();
            default:
                return this.text.trim();
            }
        },
        getSanitizedText(text){
            return text.split("_")
                .map( str => str.firstCharUpper())
                .reduce((acc, current) => acc.concat(` ${current}`));
        },
        getTextForReasonNote() {
            switch (true) {
            case (!this.text):
            case (this.text.length === 0):
                return 'N/A';
            case (this.getSanitizedText(this.text) > this.maxSizeForNote):
                return `${this.getSanitizedText(this.text).substring(0, this.maxSizeForNote)}...`.trim();
            default:
                return this.getSanitizedText(this.text).trim();
            }
        },
        onResizeTextContainer(){
            switch (true) {
            case this.windowWidth <= this.ultraWindowWidth:
            case this.windowWidth <= this.microWindowWidth:
            case this.windowWidth <= this.littleWindowWidth:
            case this.windowWidth <= this.mediumWindowWidth:
            case this.windowWidth <= this.bigWindowWidth:
            case this.windowWidth <= this.hugeWindowWidth:
                this.isTextTooltipDisabled=false;
                break;
            default:
                this.isTextTooltipDisabled=true;
                break;
            }
        },
        makeid(length) {
            let result = '';
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < length) {
                result += characters.charAt(Math.floor(generateRandomNumber() * charactersLength));
                counter += 1;
            }
            return result;
        }
    }
};
</script>

<style scoped>
.wrapper {
  overflow-wrap: break-word;
}
</style>

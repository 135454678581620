<template>
  <b-row :key="`${myKey}${Math.random()}`">
    <b-col class="no-padding">
      <b-form-select
          id="showPages"
          v-model="elementPerPage"
          :options="paginationOptions"/>
      <!--suppress XmlInvalidId -->
      <label class="page-size-label" for="showPages">
        {{ $t('admin.common.per-page') }}
      </label>
    </b-col>
    <b-col class="no-padding">
      <div class="paginator-wrapper">
        <!--suppress XmlInvalidId -->
        <label class="paginator-label" for="paginator">
          {{ $t('admin.common.page') }}
        </label>
        <b-pagination
            id="paginator"
            v-model="latestPage"
            :per-page="elementPerPage"
            :total-rows="totalItems">
          <template #prev-text>
            <div class="paginator-arrow-prev"></div>
          </template>
          <template #next-text>
            <div class="paginator-arrow-next"></div>
          </template>
        </b-pagination>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import FilterConstant from '@/components/Filter/FilterConstant';

export default {
    name: 'table-footer',
    props: {
        myKey: {
            type: String,
            default: () => 'key',
        },
        perPage: {
            type: Number,
            default: () => 10,
        },
        pageOptions: {
            type: Array,
            default: () => [],
        },
        currentPage: {
            type: Number,
            default: () => 1,
        },
        totalItems: {
            type: Number,
            default: () => 10,
        }
    },
    mixins: [FilterConstant],
    data() {
        return {
            elementPerPage: this.perPage,
            paginationOptions: this.pageOptions,
            latestPage: this.currentPage,
        };
    },
    watch: {
        elementPerPage: {
            handler(itemPerPage) {
                this.emitNewItemPerPage(itemPerPage);
            }
        },
        latestPage: {
            handler(newCurrentPage) {
                this.emitNewCurrentPage(newCurrentPage);
            }
        },
        allItems: {
            handler(newAllItems) {
                this.emitNewAllItems(newAllItems);
            }
        },
    },
    methods: {
        emitNewItemPerPage(itemPerPage) {
            this.$root
                .$emit(
                    this.NEW_ITEM_PER_PAGE_SELECTED,
                    itemPerPage
                );
        },
        emitNewCurrentPage(newCurrentPage) {
            this.$root
                .$emit(
                    this.NEW_CURRENT_PAGE_SELECTED,
                    newCurrentPage
                );
        },
        emitNewAllItems(newAllItems) {
            this.$root
                .$emit(
                    this.NEW_ALL_ITEMS_SELECTED,
                    newAllItems
                );
        },
    }
};
</script>
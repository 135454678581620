<template>
  <div class="text-center text-danger my-2">
    <b-spinner class="align-middle"></b-spinner>
    <strong>{{ $t('admin.common.loading') }}</strong>
  </div>
</template>

<script>
export default {
    name: 'table-busy'
};
</script>

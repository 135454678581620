<template>
  <div class="text-center text-danger my-2">
    <p class="h4 mb-2">
      <b-icon icon="exclamation-circle-fill" variant="danger"></b-icon>
      &nbsp;
      <strong>{{ $t('admin.common.no_result') }}</strong>
    </p>
  </div>
</template>
<script>
export default {
    name: 'table-empty-filter'
};
</script>
